import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useSaveUser } from '../hooks/useSaveUser';
import { useGetProfile } from '../hooks/useGetProfile';
import Dropdown from '../../../components/Dropdown';
import ChangePasswordDialog from './ChangePassword';

const Profile = () => {
  const form = useForm();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useGetProfile();
  const { saveUser } = useSaveUser('edit');
  const [openChangePassword, setOpenChangePassword] = useState(false) 

  useEffect(() => {
    if (data) {
      let formData = {
        ...data,
      };
      form.reset(formData);
    }
  }, [data, form]);

  const enableEditing = () => {
    setIsReadOnly(false);
    setIsEditingEnabled(true);
  };

  const cancelEditing = () => {
    setIsReadOnly(true);
    setIsEditingEnabled(false);
    let formData = {
      ...data,
      role: { label: data.role?.name, value: data.role?.id }
    };
    form.reset(formData);
  };

  const onSaveDetails = () => {
    const formData = form.getValues();
    const data = {
      ...formData,
      role: {
        id: formData.role?.value
      }
    }
    saveUser(data);
    reset();
  };

  const reset = () => {
    setDialogOpen(false);
    setIsReadOnly(true);
    setIsEditingEnabled(false);
  };

  return (
    <div className='flex flex-col gap-2 mx-4' >
        <h1>Profile</h1>
    <Form layout="vertical">
        <FormInputField
            label="Username"
            name={'username'}
            key={'username'}
            form={form}
            rules="required"
            readOnly={isReadOnly}
          />
          <FormInputField
            label=""
            name={'password'}
            key={'password'}
            form={form}
            rules="required"
            readOnly={isReadOnly}
            hidden={true}
          />
          <Dropdown 
             name="role" 
             label="Role" 
             form={form}
             rules={'required'}
             options={[{
              label: 'ADMIN',
              value: 1
             }, {
              label: 'AGENT',
              value: 2
             }]}
             hideSelectedOptions
             disabled={isReadOnly}
          />
      <div className='flex justify-end pb-0 mb-0 gap-6'>
       {!isEditingEnabled && <Button
          onClick={enableEditing}
          text='Edit'
          type="text"
          className="mr-2"
        />}
        {isEditingEnabled && (
          <Button
          type="text"
          onClick={cancelEditing}
          text='Cancel'
        />
        )}
        {isEditingEnabled && (<Button
          type="primary"
          onClick={form.handleSubmit(() => setDialogOpen('save'))}
          text='Save'
          className="mr-2"
        />
        )}
        {!isEditingEnabled && <Button
          onClick={() => setOpenChangePassword(true)}
          text='Change password'
          type="primary"
          className="mr-2"
        />}
      </div>
    </Form>
          {dialogOpen === 'save' && (
          <Modal
            description={`Are you sure you want to update your details?`}
            onConfirm={onSaveDetails}
            onClose={() => setDialogOpen(false)}
          />
        )}
        <ChangePasswordDialog open={openChangePassword} setOpen={setOpenChangePassword} id={data?.id}  />
    </div>
  );
};

export default Profile;
