import React, { useState } from 'react';
import Table from '../../../components/Table/components/Table';
import { Users as Service } from '../../../services/Users';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useDeleteUser } from '../hooks/useDeleteUser';
import { useGetProfile } from '../hooks/useGetProfile';

const Users = ({openDetails}) => {
  const [selected, setSelected] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { deleteUser } = useDeleteUser();
  const { data } = useGetProfile();
  const fetchData = params => {
    return Service.all({ params });
  };

  const onDelete = () => {
    deleteUser(selected.id);
    setIsDeleteDialogOpen(false);
    setSelected(null);
  };

  const onDeleteLink = data => {
    setSelected(data);
    setIsDeleteDialogOpen(true);
  };

const userColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'username',
    key: 'username',
    sorter: true,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_text, record) => {
      return [<Button type="link" text="View" onClick={() => openDetails('view',record.id)}/>,
        <Button type="link" text='Change Password' onClick={() => openDetails('password',record.id)} />,
        data?.id !== record.id ? <Button type="link" text='Delete' onClick={() => onDeleteLink(record)} /> : null]
    },
  },
];

  return (<>
    <Table
        fetchData={fetchData}
        columns={userColumns}
        queryKey="users"
        pageSize={5} 
      />
      {isDeleteDialogOpen && (
        <Modal
          type="delete"
          description={`Are you sure you want to delete user: ${selected.username}?`}
          onConfirm={onDelete}
          onClose={() => {
            setIsDeleteDialogOpen(false)
            setSelected(null)
          }}
        />
      )}
      </>
  );
};

export default Users;
