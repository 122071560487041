import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table/components/Table';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useDeleteRegistration } from '../hooks/useDeleteRegistration';
import Modal from '../../../components/Modal';
import { Registrations as Service } from '../../../services/Registrations';
import FormInputField from '../../../components/FormInputField';
import { useForm } from 'react-hook-form';
import Dropdown from '../../../components/Dropdown';
import styled from 'styled-components';
import { formatDate } from '../../../utils/dateUtils';
import { statusOptions } from '../../../constants';

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const FilterField = styled.div`
  flex: 1;
  min-width: 0; // This prevents flex items from overflowing
`;
const Registrations = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filters, setFilters] = useState([]);
  const fetchData = params => {
    return Service.all({ params });
  };
  const form = useForm();

  useEffect(() => {
    const subscription = form.watch((values) => {
      const flter = createFilter(values);
      setFilters(flter);
    });
    return () => subscription;
  }, [form]);

  const createFilter = (values) => {
    return values && Object.keys(values).length > 0 
    ? Object.keys(values).reduce((acc, key) => {
        const value = values[key];
        if (value || (!value && typeof value === 'boolean')) {
            if (Array.isArray(value) && value.length > 0) {
              acc.push({
                property: key,
                operator: 'in',
                value: value.map(item => item.value.toString()),
              });
            }
          else if (typeof value === 'string' && value.trim() !== '') {
            acc.push({
              property: key,
              operator: "contains",
              value: value.trim(),
            });
          } else if (typeof value === 'boolean') {
            acc.push({
              property: key,
              operator: "equals",
              value: value,
            });
          }
        }
        return acc;
      }, [])
    : [];
  }

  const { deleteRegistration } = useDeleteRegistration(() => {
    setSelected(null);
    setIsDeleteDialogOpen(false);
  }, selected);
  const navigate = useNavigate()

  const onDelete = () => {
    deleteRegistration(selected.id);
  };

  const onDeleteLink = data => {
    setSelected(data);
    setIsDeleteDialogOpen(true);
  };

  const registrationColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Contact Number',
      dataIndex: 'contactNo',
      key: 'contactNo',
      sorter: true,
    },
    {
      title: 'Package',
      dataIndex: 'showcasePackage',
      key: 'showcasePackage',
      sorter: true,
    },
    {
      title: 'Age Group',
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
    {
      title: 'Created At',
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (creationDate) => {
        return formatDate(creationDate);
      },
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        const linkTo = `/registrations/${record.id}`;
        return [<Button type="link" text="View" onClick={() => navigate(linkTo)}/>,
        <Button type="link" text='Delete' onClick={() => onDeleteLink(record)} />]
      },
    },
  ];
  return (<>
    <FilterContainer className='flex flex-row gap-2 mt-4'>
      <FilterField>
    <FormInputField form={form} label="First Name" placeholder='Search by firstname' key={'firstname'} name={'firstname'}/>
    </FilterField>
    <FilterField>
    <FormInputField form={form} label="Last Name" placeholder='Search by lastname' key={'lastname'} name={'lastname'}/>
    </FilterField>
    <FilterField>
    <FormInputField form={form} label="Email" placeholder='Search by email' key={'email'} name={'email'}/>
    </FilterField>
    <FilterField>
    <Dropdown options={statusOptions} form={form} isMulti label="Filter by status" key={'status'} name={'status'}/>
    </FilterField>
    </FilterContainer>

    <Table
        fetchData={fetchData}
        columns={registrationColumns}
        queryKey="registrations"
        filters={filters}
      />
      {isDeleteDialogOpen && (
        <Modal
          type="delete"
          description={`Are you sure you want to delete registration ${selected.name}?`}
          onConfirm={onDelete}
          onClose={() => setIsDeleteDialogOpen(false)}
        />
      )}
      </>
  );
};

export default Registrations;
