import React, { useState } from 'react';
import { Table as AntTable, Button } from 'antd';
import { useQuery } from 'react-query';
import { CSVLink } from "react-csv";
const ReusableTable = ({ fetchData, queryKey, columns, filters }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [sortOrder, setSortOrder] = useState({ field: 'id', order: 'ascend' });

  const { data, isLoading, refetch } = useQuery({
    queryKey: [queryKey, pagination.current, pagination.pageSize, sortOrder, filters],
    queryFn: () => fetchData({
      filters: filters || [],
      pagination: {
        page: pagination.current - 1,
        size: pagination.pageSize,
        sortBy: sortOrder.field,
        sortDirection: sortOrder.order === 'ascend' ? 'asc' : 'desc',
      },
    }),
    keepPreviousData: true, 
  });

  const handleTableChange = (
    newPagination,
    filters,
    sorter,
  ) => {
    const sortField = sorter.field || 'id';
    setSortOrder({ field: sortField, order: sorter.order || 'ascend' });

    setPagination({
      ...newPagination,
    });

    refetch();
  };

  const prepareDataForCSV = (data) => {
    return data.map(item => {
      const preparedItem = {};
      
      Object.entries(item).forEach(([key, value]) => {
        if (value === null || value === undefined) {
          preparedItem[key] = '';
        } else if (Array.isArray(value) || typeof value === 'object') {
          // Convert both arrays and objects to JSON string
          preparedItem[key] = JSON.stringify(value);
        } else {
          preparedItem[key] = value;
        }
      });
      
      return preparedItem;
    });
  };

  return (
    <>
    <CSVLink 
      data={prepareDataForCSV(data?.content || [])} 
      filename={`${queryKey}-${new Date().toISOString().split('T')[0]}.csv`} 
      className='float-right mb-2'
    >
      <Button>Export to CSV</Button>
    </CSVLink>
    <AntTable
      className="compact-table my-2"
      columns={columns}
      dataSource={data?.content?.map((item) => ({ ...item, key: item.id }))}
      loading={isLoading}
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: data?.page?.totalElements || 0,
      }}
      onChange={handleTableChange} 
      rowKey={(record) => record.key} 
    />
    </>
  );
};

export default ReusableTable;
